import { AnyAction } from 'redux';
import { createReducer } from 'redux-create-reducer';

import { IActivityLogState } from 'src/app/interfaces/IActivityLog';
import {
  FetchFilteredActivityLog,
  HideActivityLogFilterDrawer,
  SetActivityLogRequestDate,
  SetAppliedFilters,
  SetSelectedUsers,
  SetInitialDateTimeRange,
  SetUserSelectedDateRange,
  ShowActivityLogFilterDrawer,
  ResetAllFilters,
  SetEventSubTypesFilter,
  SetIsUserTouchedDateRangeFilter,
  SetUserStateToInitialState,
  SetActivityLogTableSorting,
  SetSearchField,
  HideErrorBanner,
} from './actions';

export const initialState: IActivityLogState = {
  appliedFilters: {
    pageIndex: 0,
    pageSize: 30,
  },
  activityLogData: {
    data: {
      currentPage: 0,
      totalPages: 0,
      totalRecords: 0,
      logs: [],
    },
    error: null,
    isLoading: false,
  },
  sortingOptions: [{ id: 'eventDate', desc: true }],
  activityLogRequestDate: '',
  isActivityLogFilterDrawerVisible: false,
  hasUserTouchedDateRangeFilter: false,
  hasUserTouchedSelectorsFilter: false,
  initialDrawerFilters: {
    initialFetchedRange: null,
    eventSubTypes: [],
    selectedUsers: [],
  },
  userSelectedDrawerFilters: {
    userSelectedDateRange: null,
    userSelectedEventSubTypes: [],
    userSelectedUsers: [],
  },
  searchField: '',
};

export default createReducer(initialState, {
  [FetchFilteredActivityLog.Pending.TYPE]: (state: IActivityLogState) => ({
    ...state,
    activityLogData: {
      ...initialState.activityLogData,
      isLoading: true,
    },
  }),

  [FetchFilteredActivityLog.Success.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    activityLogData: {
      ...initialState.activityLogData,
      data: payload,
      isLoading: false,
    },
  }),

  [FetchFilteredActivityLog.Error.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    activityLogData: {
      ...initialState.activityLogData,
      error: payload,
      isLoading: false,
    },
  }),

  [ShowActivityLogFilterDrawer.TYPE]: (state: IActivityLogState) => ({
    ...state,
    isActivityLogFilterDrawerVisible: true,
  }),

  [HideActivityLogFilterDrawer.TYPE]: (state: IActivityLogState) => ({
    ...state,
    isActivityLogFilterDrawerVisible: false,
  }),

  [SetAppliedFilters.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    appliedFilters: {
      ...state.appliedFilters,
      ...payload,
    },
  }),

  [SetSelectedUsers.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    hasUserTouchedSelectorsFilter: true,
    userSelectedDrawerFilters: {
      ...state.userSelectedDrawerFilters,
      userSelectedUsers: payload,
    },
  }),

  [SetInitialDateTimeRange.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    initialDrawerFilters: {
      ...state.initialDrawerFilters,
      initialFetchedRange: payload.initialFetchedRange,
    },
    hasUserTouchedDateRangeFilter: false,
  }),

  [SetUserSelectedDateRange.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    hasUserTouchedDateRangeFilter: true,
    userSelectedDrawerFilters: {
      ...state.userSelectedDrawerFilters,
      userSelectedDateRange: payload.userSelectedDateRange,
    },
  }),

  [SetActivityLogRequestDate.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    activityLogRequestDate: payload,
  }),

  [ResetAllFilters.TYPE]: (state: IActivityLogState) => ({
    ...state,
    ...initialState,
  }),

  [SetEventSubTypesFilter.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    hasUserTouchedSelectorsFilter: true,
    userSelectedDrawerFilters: {
      ...state.userSelectedDrawerFilters,
      userSelectedEventSubTypes: payload,
    },
  }),

  [SetIsUserTouchedDateRangeFilter.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    hasUserTouchedDateRangeFilter: payload,
  }),

  [SetUserStateToInitialState.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => {
    const {
      userSelectedDateRange,
      userSelectedEventSubTypes,
      userSelectedUsers,
    } = payload;

    return {
      ...state,
      initialDrawerFilters: {
        initialFetchedRange: userSelectedDateRange?.every(
          (date: Date[]) => date !== null
        )
          ? userSelectedDateRange
          : state.initialDrawerFilters.initialFetchedRange,
        eventSubTypes: userSelectedEventSubTypes,
        selectedUsers: userSelectedUsers,
      },
      userSelectedDrawerFilters: {
        ...state.userSelectedDrawerFilters,
        userSelectedDateRange: null,
      },
      hasUserTouchedDateRangeFilter: false,
      hasUserTouchedSelectorsFilter: false,
      appliedFilters: {
        ...state.appliedFilters,
        pageIndex: 0,
      },
    };
  },

  [SetSearchField.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    searchField: payload,
    appliedFilters: {
      ...state.appliedFilters,
      pageIndex: 0,
    },
  }),

  [SetActivityLogTableSorting.TYPE]: (
    state: IActivityLogState,
    { payload }: AnyAction
  ) => ({
    ...state,
    sortingOptions: payload,
  }),

  [HideErrorBanner.TYPE]: (state: IActivityLogState) => ({
    ...state,
    activityLogData: {
      ...initialState.activityLogData,
      error: null,
    },
  }),
});
