import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import { capitalize, filter, mapValues, sortBy } from 'lodash';
import { IRole } from 'src/app/interfaces/IUserRoles';
import {
  IInputData,
  IMapGroupFormRow,
  IRowValidErrorState,
} from './MapIdentityProvidersGroups/interfaces';
import { MappingStatuses } from './MapIdentityProvidersGroups/MappingStatus';
import { IGroup } from './new-group/interfaces';
import { IUser } from '../../interfaces/ICommon';
import {
  IFormattedGroupUser,
  IGroupMapping,
  IGroupMappingWithName,
  IGroupRole,
  IGroupUser,
  IOption as IGroupOption,
  IProduct,
} from '../../interfaces/IGroups';

import { IDependentOption, IOption, IRolesRows } from '../users/interfaces';

const getIsGroupNameUnique = (
  allGroups: IGroup[],
  groupName: string
): boolean =>
  !allGroups.some(
    ({ name }) => name.trim().toLowerCase() === groupName.trim().toLowerCase()
  );

const extractProductsFromRoles = (assignedRoles: IGroupRole[]): IProduct[] => {
  return assignedRoles.reduce<IProduct[]>((unique, role) => {
    if (!unique.some((product) => product.value === role.product)) {
      unique.push({ label: role.product, value: role.product });
    }
    return unique;
  }, []);
};

const extractUsersOptions = (
  users: IUser[],
  groupUsers: IFormattedGroupUser[] = []
): CDSOption[] => {
  const filteredUsers = users.filter(
    (user) => !groupUsers.find((groupUser) => user.id === groupUser.userId)
  );
  return filteredUsers.map((user: IUser) => {
    const { firstName, lastName, email } = user?.profile;
    return {
      id: user.id,
      value: email ?? '',
      label: `${firstName} ${lastName} - ${email}`,
      selected: false,
    };
  });
};

const combineWithSelectedOptions = (
  selectedOptions: CDSOption[],
  options: CDSOption[]
): CDSOption[] => {
  return [...selectedOptions, ...options].reduce(
    (acc: CDSOption[], current) => {
      const duplicateOption = acc.find(
        (option: CDSOption) => option.value === current.value
      );
      if (!duplicateOption) {
        acc.push(current);
      }
      return acc;
    },
    []
  );
};

const formatGroupUsers = (
  groupUsers: IGroupUser[],
  allUsers: IUser[]
): IFormattedGroupUser[] => {
  return groupUsers.map((groupUser) => {
    const userInfo = allUsers.find(
      (user) => groupUser.userId === user.id
    ) as IUser;
    return {
      ...groupUser,
      email: userInfo?.profile?.email,
      firstname: userInfo?.profile?.firstName,
      lastname: userInfo?.profile?.lastName,
      // this type does not come from BE, assume there is only Local type for now
      type: 'Local',
      status: capitalize(userInfo?.status),
      lastLogin: userInfo?.lastLogin,
    };
  }) as IFormattedGroupUser[];
};

const formatGroupRoles = (
  assignedRoles: IGroupRole[],
  allRoles: IRole[]
): IGroupRole[] => {
  const matchedRoles = assignedRoles
    .map((assignedRole: IGroupRole) => {
      const matchedRole = allRoles.find(
        (role: IRole) => role.id === assignedRole.roleId
      );
      const output = matchedRole
        ? {
            ...assignedRole,
            id: assignedRole.id,
            role: matchedRole.roleDisplayName,
            product: matchedRole.product,
            type: matchedRole.type,
            productKey: matchedRole.productKey,
            delegatable: matchedRole.delegatable,
          }
        : false;

      return output;
    })
    .filter(Boolean) as IGroupRole[];

  return sortBy(matchedRoles, (role) => role.product);
};

const filterOptionByProduct = (
  selectedProductOption: CDSOption | undefined,
  option: CDSOption & { label: string },
  splitArg: string = ' '
): boolean | CDSOption => {
  const productName = option?.label?.split(splitArg).shift();
  const checkMatch = (selectedProduct: CDSOption): boolean =>
    selectedProduct.value === productName;

  const result = selectedProductOption
    ? checkMatch(selectedProductOption)
    : option;

  return result;
};

const filterOptionByAssignedRoles = (
  assignedRoles: IGroupRole[],
  option: CDSOption
): boolean => {
  const hasMatch = assignedRoles.find(
    (role: IGroupRole) => role.roleId === option.value
  );
  return !hasMatch;
};

const removeGroupRolesFromRolesOptions = (
  productRolesOptions: IDependentOption,
  rbacAssignedRoles: IGroupRole[]
): IDependentOption => {
  const filteredRoles = (rolesOptions: IOption[]): IOption[] =>
    filter(rolesOptions, (rolesOption) => {
      return !rbacAssignedRoles
        .map((rbacAssignedRole) => rbacAssignedRole.roleId)
        .includes(rolesOption.value);
    });

  return mapValues(productRolesOptions, (value) => filteredRoles(value));
};

const removeProductsOptionsWithoutUnassignedRoles = (
  productsOptions: IOption[],
  unassignedRolesOptions: IDependentOption
): IOption[] => {
  return productsOptions.filter((productsOption) =>
    Boolean(unassignedRolesOptions[productsOption.value].length)
  );
};

const getRoleName = (
  selectedProductsInAssignRolesDrawer: IRolesRows[]
): string | null => {
  const selectedProduct =
    selectedProductsInAssignRolesDrawer[0].selectedProduct;
  if (selectedProduct) {
    const selectedOption = selectedProductsInAssignRolesDrawer[0].rolesOptions[
      selectedProduct
    ].find((option: IOption) => option.selected);
    return selectedOption?.label ?? null;
  }
  return null;
};

const sliceTableData = <T>(
  pageIndex: number,
  pageSize: number,
  users: T[]
): T[] => {
  const from = pageIndex * pageSize;
  const to = (pageIndex + 1) * pageSize;
  return users.slice(from, to);
};

export const getNewStatusFromGroupOption = (
  item: IMapGroupFormRow,
  newSccGroupId: string,
  existingMapping?: IGroupMappingWithName
): MappingStatuses => {
  if (item.rowStatus === MappingStatuses.Added) {
    return MappingStatuses.Added;
  }

  if (!existingMapping) {
    return item.rowStatus as MappingStatuses;
  }

  const oldSccGroupId = existingMapping?.sccGroupId;
  const isRightChanged = newSccGroupId !== oldSccGroupId;

  if (isRightChanged) {
    if (item.rowStatus === MappingStatuses.LeftChanged) {
      return MappingStatuses.Changed;
    }

    return MappingStatuses.RightChanged;
  }

  if (item.rowStatus === MappingStatuses.Changed) {
    return MappingStatuses.LeftChanged;
  }

  return MappingStatuses.Unchanged;
};

export const getNewStatusFromIdpOption = (
  item: IMapGroupFormRow,
  newIdpId: string,
  existingMapping?: IGroupMappingWithName
): MappingStatuses => {
  if (item.rowStatus === MappingStatuses.Added) {
    return MappingStatuses.Added;
  }

  if (!existingMapping) {
    return item.rowStatus as MappingStatuses;
  }

  const oldIdpId = existingMapping?.idpId;
  const isRightChanged = newIdpId !== oldIdpId;

  if (isRightChanged) {
    if (item.rowStatus === MappingStatuses.LeftChanged) {
      return MappingStatuses.Changed;
    }

    return MappingStatuses.RightChanged;
  }

  if (item.rowStatus === MappingStatuses.Changed) {
    return MappingStatuses.LeftChanged;
  }

  return MappingStatuses.Unchanged;
};

export const selectIdpOption = (
  optionRows: IMapGroupFormRow[],
  selectedOption: IOption,
  currentRowIndex: number
): IMapGroupFormRow[] => {
  return checkForDuplicateIdpGroupNames(
    checkForDuplicateSccGroups(
      optionRows.reduce<IMapGroupFormRow[]>(
        (newRows, item: IMapGroupFormRow, index: number) => {
          if (currentRowIndex === index) {
            newRows.push({
              ...item,
              rowStatus: MappingStatuses.Added,
              idpName: {
                ...item.idpName,
                value: selectedOption.value,
                selectedOption,
              },
            });
            if (item.rowStatus !== MappingStatuses.Added) {
              newRows.push({
                ...item,
                rowStatus: MappingStatuses.Deleted,
                idpName: {
                  ...item.idpName,
                },
              });
            }
          } else {
            newRows.push(item);
          }
          return newRows;
        },
        []
      )
    )
  );
};

export const selectGroupOption = (
  optionRows: IMapGroupFormRow[],
  selectedOption: IOption,
  currentRowIndex: number,
  existingMappings: IGroupMappingWithName[]
): IMapGroupFormRow[] => {
  const existingMapping = existingMappings[currentRowIndex];

  return checkForDuplicateSccGroups(
    optionRows.map((item: IMapGroupFormRow, index: number) => {
      if (currentRowIndex === index) {
        return {
          ...item,
          rowStatus: getNewStatusFromGroupOption(
            item,
            selectedOption.value,
            existingMapping
          ),
          sccGroupName: {
            ...item.sccGroupName,
            value: selectedOption.value,
            selectedOption,
          },
        };
      }
      return item;
    })
  );
};

export const getNewStatusFromInput = (
  item: IMapGroupFormRow,
  newIdpGroupName: string,
  existingMapping?: IGroupMappingWithName
): MappingStatuses => {
  if (item.rowStatus === MappingStatuses.Added) {
    return MappingStatuses.Added;
  }

  if (!existingMapping) {
    return item.rowStatus as MappingStatuses;
  }

  const oldIdpGroupName = existingMapping.idpGroupName;
  const isLeftChanged = newIdpGroupName !== oldIdpGroupName;

  if (isLeftChanged) {
    if (item.rowStatus === MappingStatuses.RightChanged) {
      return MappingStatuses.Changed;
    }

    return MappingStatuses.LeftChanged;
  }

  if (item.rowStatus === MappingStatuses.Changed) {
    return MappingStatuses.RightChanged;
  }

  return MappingStatuses.Unchanged;
};

export const updateInputField = (
  rowsData: IMapGroupFormRow[] | any,
  inputData: IInputData,
  existingMappings: IGroupMappingWithName[]
): any => {
  const { value, name, rowIndex } = inputData;

  const existingMapping = existingMappings[rowIndex];

  return rowsData.map((row: any, index: number) => {
    if (index === rowIndex) {
      return {
        ...row,
        rowStatus: getNewStatusFromInput(row, value, existingMapping),
        [name]: { ...row[name], value },
      };
    }
    return row;
  });
};

export const getIsValidationByRegexpPassed = (value: string): boolean => {
  const fieldValidation = /^[a-zA-Z\d-_.]+$/;
  return fieldValidation.test(value);
};

export const getIdpGroupNameErrorMessage = (value: string): string => {
  if (value.length === 0) {
    return 'noChars';
  }
  if (value.length > 128) {
    return 'moreThanLimitChars';
  }
  if (!getIsValidationByRegexpPassed(value)) {
    return 'regexpNotPassed';
  }
  return '';
};

export const checkForDuplicateIdpGroupNames = (
  rowData: IMapGroupFormRow[]
): IMapGroupFormRow[] => {
  const updatedRowsData = [...rowData];
  const getKey = (row: IMapGroupFormRow): string =>
    `${row.idpName.value}:${row.idpGroupName.value}`;

  const duplicateMap = updatedRowsData.reduce<Map<string, number[]>>(
    (duplicateCountMap, row, index) => {
      if (row.rowStatus !== MappingStatuses.Deleted) {
        duplicateCountMap.set(getKey(row), [
          ...(duplicateCountMap.get(getKey(row)) || []),
          index,
        ]);
      }
      return duplicateCountMap;
    },
    new Map<string, number[]>()
  );

  duplicateMap.forEach((values, key, map) => {
    if (values.length > 1) {
      values.forEach((index) => {
        updatedRowsData[index] = {
          ...updatedRowsData[index],
          idpGroupName: {
            ...updatedRowsData[index].idpGroupName,
            errorStatus: true,
            errorMessage: 'duplicateIdpGroupNames',
          },
        };
      });
    } else {
      updatedRowsData[values[0]] = {
        ...updatedRowsData[values[0]],
        idpGroupName: {
          ...updatedRowsData[values[0]].idpGroupName,
          errorStatus: false,
          errorMessage: '',
        },
      };
    }
  });

  return updatedRowsData;
};

export const checkForDuplicateSccGroups = (
  rowData: IMapGroupFormRow[]
): IMapGroupFormRow[] => {
  const updatedRowsData = [...rowData];

  const getKey = (row: IMapGroupFormRow): string => `${row.sccGroupName.value}`;

  const duplicateMap = updatedRowsData.reduce<Map<string, number[]>>(
    (duplicateCountMap, row, index) => {
      if (row.rowStatus !== MappingStatuses.Deleted) {
        duplicateCountMap.set(getKey(row), [
          ...(duplicateCountMap.get(getKey(row)) || []),
          index,
        ]);
      }
      return duplicateCountMap;
    },
    new Map<string, number[]>()
  );

  duplicateMap.forEach((values) => {
    if (values.length > 1) {
      values.forEach((index) => {
        updatedRowsData[index] = {
          ...updatedRowsData[index],
          sccGroupName: {
            ...updatedRowsData[index].sccGroupName,
            errorStatus: true,
            errorMessage: 'duplicateSccGroups',
          },
        };
      });
    } else {
      updatedRowsData[values[0]] = {
        ...updatedRowsData[values[0]],
        sccGroupName: {
          ...updatedRowsData[values[0]].sccGroupName,
          errorStatus: false,
          errorMessage: '',
        },
      };
    }
  });

  return updatedRowsData;
};

export const validateInputRowData = (
  rowsData: IMapGroupFormRow[] | any,
  inputData: IInputData
): any => {
  const { value, name, rowIndex } = inputData;
  const errorMessage = getIdpGroupNameErrorMessage(value);
  const errorStatus = Boolean(errorMessage);
  const updatedRowsData = [...rowsData];
  if (
    errorStatus &&
    updatedRowsData[rowIndex].rowStatus !== MappingStatuses.Deleted
  ) {
    updatedRowsData[rowIndex] = {
      ...updatedRowsData[rowIndex],
      [name]: {
        ...updatedRowsData[rowIndex][name],
        errorStatus,
        errorMessage,
      },
    };

    return updatedRowsData;
  }

  return checkForDuplicateIdpGroupNames(rowsData);
};

export const getOptionLabelByValue = (
  options: IOption[],
  value: string
): string => {
  const selectedOption = options.find(
    (option: IOption) => option.value === value
  );
  return selectedOption?.label ?? '';
};

export const updateRowDataWithRemoveStatus = (
  mapGroupsData: IMapGroupFormRow[],
  currentRow: number
): IMapGroupFormRow[] => {
  return checkForDuplicateIdpGroupNames(
    checkForDuplicateSccGroups(
      mapGroupsData.reduce<IMapGroupFormRow[]>((acc, row, index) => {
        if (index === currentRow) {
          if (row.rowStatus === MappingStatuses.Added) {
            return acc;
          } else {
            acc.push({ ...row, rowStatus: MappingStatuses.Deleted });
            return acc;
          }
        }

        acc.push(row);

        return acc;
      }, [])
    )
  );
};

export const formMappedGroupRow = (
  existingGroupMapping: IGroupMapping[],
  rbacGroupOptions: IGroupOption[],
  idpIdpOptions: IGroupOption[]
): IMapGroupFormRow[] =>
  existingGroupMapping.map((groupRow: IGroupMapping) => {
    return {
      idpName: {
        value: groupRow.idpId,
        selectedOption: {
          value: groupRow.idpId,
          label: getOptionLabelByValue(idpIdpOptions, groupRow.idpId),
          selected: true,
          errorStatus: false,
          errorMessage: '',
        },
        errorMessage: '',
        errorStatus: false,
      },
      idpGroupName: {
        value: groupRow.idpGroupName,
        errorStatus: false,
        errorMessage: '',
      },
      sccGroupName: {
        value: groupRow.rbacGroupId,
        selectedOption: {
          value: groupRow.rbacGroupId,
          label: getOptionLabelByValue(rbacGroupOptions, groupRow.rbacGroupId),
          selected: true,
          errorStatus: false,
          errorMessage: '',
        },
        errorMessage: '',
        errorStatus: false,
      },
      rowStatus: MappingStatuses.Unchanged,
    };
  });

export const getChangeSummaryTextKey = (status: MappingStatuses): string => {
  const statusSummaryMap: Record<MappingStatuses, string> = {
    [MappingStatuses.Added]: 'groups:mapIdpGroups.thirdStep.summary.added',
    [MappingStatuses.Deleted]: 'groups:mapIdpGroups.thirdStep.summary.deleted',
    [MappingStatuses.Unchanged]:
      'groups:mapIdpGroups.thirdStep.summary.unchanged',
    [MappingStatuses.Changed]: 'groups:mapIdpGroups.thirdStep.summary.changed',
    [MappingStatuses.LeftChanged]:
      'groups:mapIdpGroups.thirdStep.summary.leftChanged',
    [MappingStatuses.RightChanged]:
      'groups:mapIdpGroups.thirdStep.summary.rightChanged',
  };

  return statusSummaryMap[status];
};

export const getRowsState = (
  mapGroupsData: IMapGroupFormRow[],
  stepperMode: 'edit' | 'create'
): boolean => {
  const nonInitialRows = mapGroupsData.filter((g) => g.rowStatus);

  const hasOnlyNonDeletedEmptyRows = nonInitialRows.length
    ? !nonInitialRows.some((g) => g.sccGroupName.value && g.idpGroupName.value)
    : true;

  const rowsState = mapGroupsData.map((item: IMapGroupFormRow) => {
    return {
      error:
        item.rowStatus !== MappingStatuses.Deleted &&
        (item.idpGroupName.errorStatus ||
          item.sccGroupName.errorStatus ||
          item.idpName.errorStatus),
      isUnchanged: item.rowStatus === MappingStatuses.Unchanged,
    };
  });
  const isErrorFieldExist = rowsState.find(
    (item: IRowValidErrorState) => item.error
  );

  return (
    Boolean(isErrorFieldExist) ||
    (stepperMode === 'create' && hasOnlyNonDeletedEmptyRows)
  );
};

export {
  combineWithSelectedOptions,
  extractProductsFromRoles,
  extractUsersOptions,
  filterOptionByAssignedRoles,
  filterOptionByProduct,
  formatGroupRoles,
  formatGroupUsers,
  getIsGroupNameUnique,
  getRoleName,
  removeGroupRolesFromRolesOptions,
  removeProductsOptionsWithoutUnassignedRoles,
  sliceTableData,
};
