import { IMapGroupFormRow } from './interfaces';
import { MappingStatuses } from './MappingStatus';

export const MAX_MAP_GROUPS_ROWS = 100;

export const emptyMapGroupsRow: IMapGroupFormRow = {
  idpName: {
    value: '',
    selectedOption: null,
    errorMessage: '',
    errorStatus: false,
  },
  idpGroupName: { value: '', errorMessage: '', errorStatus: false },
  sccGroupName: {
    value: '',
    selectedOption: null,
    errorMessage: '',
    errorStatus: false,
  },
  rowStatus: MappingStatuses.Added,
};

export const mapGroupsLocalizationKey = 'groups:mapIdpGroups:secondStep';
