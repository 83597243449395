import { CDSOption } from '@ciscodesignsystems/cds-react-select';
import { CDSTableSelection } from '@ciscodesignsystems/cds-react-table';
import { AxiosError } from 'axios';
import {
  assign,
  chain,
  countBy,
  filter,
  find,
  findIndex,
  forEach,
  isEmpty,
  isEqual,
  map,
  reduce,
  sortBy,
  uniq,
  uniqBy,
} from 'lodash';
import { ProductKeys, ProductPermissionStatus } from 'src/app/enums/Products';
import { IProductInstance, IUser } from 'src/app/interfaces/ICommon';
import { capitalizeWord } from 'src/app/utils/shared';
import {
  API_ERROR_GROUP_ASSIGNED_TO_ROLE,
  API_ERROR_USER_ASSIGNED_TO_ROLE,
  ERROR,
  MAX_INHERITED_ROLES_VISIBLE_TEXT_LENGHT,
} from './constants';
import { MappingStatuses } from './MappingStatus';
import { UIUserStatus, UserStatus } from '../../enums/UserStatus';
import i18n from '../../i18n';
import {
  ApplicationScopeIdToScope,
  IAssignUserRolesRelationsData,
  IAssignedRolesGroup,
  ICDSOption,
  ICustomRole,
  IField,
  IGroupResp,
  IIndicatorPanelConfig,
  IModifiedUser,
  IPartialCustomRole,
  IProduct,
  IProductCapabilities,
  IProductCapabilitiesData,
  IProductCapabilitiesTableData,
  IProductCapabilityOption,
  IProductCapabilityOptionGroup,
  IRbacUserRole,
  IRoleToGroupRelationResp,
  IRoleToGroupsRelationsResp,
  IRoleToUserRelation,
  IRolesAcc,
  ISelectField,
  ISelectableProductInstances,
  IUserRoleResp,
  PermissionTableScopes,
} from '../../interfaces/IUserRoles';
import {
  ENTERPRISE_PRODUCT_NAME,
  ENTERPRISE_PRODUCT_TYPE,
  FEATURE_FLAG_CONSTANTS,
} from '../../utils/constant';
import {
  getProductInstanceDisplayName,
  getProvisionedProductInstances,
} from '../common/helpers';

export const getUIUserStatus: (user: IUser) => UIUserStatus = (user) => {
  return UIUserStatus[UserStatus[user.status]];
};

export type TGetUsersDataByRole = (
  selectedUserRole: IUserRoleResp,
  allEnterpriseUsersItems: IUser[],
  roleToUsersRelationsItems: IRoleToUserRelation[],
  allProductsInstancesItems: IProductInstance[],
  useRoleDescriptionForProductInstanceDisplay?: boolean
) => IModifiedUser[];
export const getUsersDataByRole: TGetUsersDataByRole = (
  selectedUserRole,
  allEnterpriseUsersItems,
  roleToUsersRelationsItems,
  allProductsInstancesItems,
  useRoleDescriptionForProductInstanceDisplay
) => {
  if (!selectedUserRole.id) {
    return [];
  }

  const usersBySelectedRole = reduce(
    roleToUsersRelationsItems,
    (acc: IModifiedUser[], userToRoleRelation: IRoleToUserRelation) => {
      const user: IUser | undefined = find(allEnterpriseUsersItems, {
        id: userToRoleRelation.userId,
      });

      if (!user) {
        return acc;
      }

      const productInstance: IProductInstance | undefined = find(
        allProductsInstancesItems,
        selectedUserRole.productKey === ENTERPRISE_PRODUCT_TYPE
          ? {
              productType: ENTERPRISE_PRODUCT_TYPE,
            }
          : {
              id: userToRoleRelation.tenantId,
            }
      );

      if (!productInstance) {
        return acc;
      }

      acc.push({
        id: user.id,
        userToRoleRelation,
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        email: user.profile.email,
        status: getUIUserStatus(user),
        productInstanceDisplayName: productInstance
          ? getProductInstanceDisplayName(
              productInstance,
              useRoleDescriptionForProductInstanceDisplay
            )
          : '',
      });

      return acc;
    },
    []
  );

  return usersBySelectedRole;
};

export type TGetInheritedRolesCutted = (
  allUserRoles: IUserRoleResp[],
  inheritedRolesIds: string[]
) => string;
export const getInheritedRolesCutted: TGetInheritedRolesCutted = (
  allUserRoles,
  inheritedRolesIds
) => {
  let inheritedRolesAmount: number = inheritedRolesIds.length;

  // If no items - return empty string
  if (!inheritedRolesAmount) {
    return '';
  }

  const inheritedRolesByProducts: Record<
    string,
    { rolesCount: number; rolesText: string }
  > = reduce(
    inheritedRolesIds,
    (
      rolesAcc: Record<string, { rolesCount: number; rolesText: string }>,
      roleId: string
    ) => {
      const role: IUserRoleResp | undefined = find(allUserRoles, {
        id: roleId,
      });

      if (role) {
        const { product, roleDisplayName } = role;
        if (!rolesAcc[product]) {
          rolesAcc[product] = {
            rolesCount: 1,
            rolesText: `${product}: ${roleDisplayName}`,
          };
        } else {
          rolesAcc[product] = {
            rolesCount: rolesAcc[product].rolesCount + 1,
            rolesText: rolesAcc[product].rolesText.concat(
              ', ',
              roleDisplayName
            ),
          };
        }
      } else {
        inheritedRolesAmount -= 1;
      }

      return rolesAcc;
    },
    {}
  );

  const { inheritedRolesText, itemsCounter, shortestRole } = reduce(
    inheritedRolesByProducts,
    (
      acc: IRolesAcc,
      inheritedRolesByProductData: { rolesCount: number; rolesText: string }
    ) => {
      const concatedWithNewRoles: string = `${acc.inheritedRolesText}${
        acc.inheritedRolesText.length ? '; ' : ''
      }${inheritedRolesByProductData.rolesText}`;
      if (
        concatedWithNewRoles.length < MAX_INHERITED_ROLES_VISIBLE_TEXT_LENGHT
      ) {
        acc.inheritedRolesText = concatedWithNewRoles;
        acc.itemsCounter =
          acc.itemsCounter - inheritedRolesByProductData.rolesCount;
      } else if (!acc.inheritedRolesText) {
        // If we do not have short "inheritedRolesText" that fit table column we are searching for shortest role to display it
        // even if it is longer and could display in 2 rows because at least 1 should be displayed
        if (
          !acc.shortestRole.length ||
          inheritedRolesByProductData.rolesText.length < acc.shortestRole.length
        ) {
          acc.shortestRole = inheritedRolesByProductData.rolesText;
          inheritedRolesAmount -= 1;
        }
      }

      return acc;
    },
    {
      itemsCounter: inheritedRolesAmount,
      inheritedRolesText: '',
      shortestRole: '',
    }
  );
  const cuttedRolesNumber: string = itemsCounter ? ` ${itemsCounter}+` : '';
  // If we have not empty inheritedRoles - using cuttedRolesNumber
  // If don't then we display only one shortest role, so count of hidden roles is amount of all roles - 1
  const hiddenRolesAmount = inheritedRolesText
    ? cuttedRolesNumber
    : inheritedRolesAmount || '';

  return `${inheritedRolesText || shortestRole}${hiddenRolesAmount}`;
};

export type TGetGroupsDataByRole = (
  selectedUserRole: IUserRoleResp,
  userRoles: IUserRoleResp[],
  roleToGroupsRelations: IRoleToGroupsRelationsResp[],
  allProductsInstancesItems: IProductInstance[],
  useRoleDescriptionForProductInstanceDisplay?: boolean
) => IAssignedRolesGroup[];
export const getGroupsDataByRole: TGetGroupsDataByRole = (
  selectedUserRole,
  userRoles,
  roleToGroupsRelations,
  allProductsInstancesItems,
  useRoleDescriptionForProductInstanceDisplay
) => {
  const selectedUserRoleId = selectedUserRole.id;

  if (!selectedUserRoleId) {
    return [];
  }

  const groupsBySelectedRole: IAssignedRolesGroup[] = reduce(
    roleToGroupsRelations,
    (
      acc: IAssignedRolesGroup[],
      roleToGroupsRelation: IRoleToGroupsRelationsResp
    ) => {
      const { rbacGroup, mappedRoles } = roleToGroupsRelation;
      // TODO: tmp solution. Need to display all roles assigned to Group - in columns "Inherited roles"
      // For this we should have list of IDs all assigned UserRoles to current Group
      const groupUniqMappedRoles = uniqBy(mappedRoles, 'groupId');
      const roles = reduce(
        groupUniqMappedRoles,
        (
          rolesAcc: {
            inheritedRoles: Record<string, string>;
            assignedRoles: Record<string, string[]>;
          },
          relation: IRoleToGroupRelationResp
        ) => {
          const role = find(userRoles, { id: relation.roleId });

          if (role) {
            const { product, roleDisplayName } = role;
            if (!rolesAcc.inheritedRoles[product]) {
              rolesAcc.inheritedRoles[product] =
                `${product}: ${roleDisplayName}`;
              rolesAcc.assignedRoles[product] = [roleDisplayName];
            } else {
              rolesAcc.inheritedRoles[product] = rolesAcc.inheritedRoles[
                product
              ].concat(', ', roleDisplayName);
              rolesAcc.assignedRoles[product].push(roleDisplayName);
            }
          }

          return rolesAcc;
        },
        {
          assignedRoles: {},
          inheritedRoles: {},
        }
      );

      const inheritedRolesCutted = getInheritedRolesCutted(
        userRoles,
        roleToGroupsRelation.inheritedRoles
      );

      // Herer we ase rearching and saving data about relations between User role and Groups in scope of Product Instances
      forEach(mappedRoles, (roleToGroupRelation: IRoleToGroupRelationResp) => {
        const productInstance: IProductInstance | undefined = find(
          allProductsInstancesItems,
          selectedUserRole.productKey === ENTERPRISE_PRODUCT_TYPE
            ? {
                productType: ENTERPRISE_PRODUCT_TYPE,
              }
            : {
                id: roleToGroupRelation.tenantId,
              }
        );

        if (!productInstance) {
          return;
        }

        const productInstanceDisplayName = getProductInstanceDisplayName(
          productInstance,
          useRoleDescriptionForProductInstanceDisplay
        );
        const assignedRolesGroup: IAssignedRolesGroup = {
          id: rbacGroup.id,
          name: rbacGroup.name,
          description: rbacGroup.description,
          assignedRoles: roles.assignedRoles,
          inheritedRoles: inheritedRolesCutted,
          roleToGroupRelationId: roleToGroupRelation.id,
          tenantId: roleToGroupRelation.tenantId,
          productInstanceDisplayName,
          type: rbacGroup.type,
        };
        acc.push(assignedRolesGroup);
      });

      return acc;
    },
    []
  );

  return groupsBySelectedRole;
};

export const getAssignRolesDrawerHeader: (
  selectedUserRolesItems: IUserRoleResp[]
) => string = (selectedUserRolesItems) => {
  return i18n.t('userRoles:assignRolesDrawer:header.txt', {
    count: selectedUserRolesItems?.length || 1,
  });
};

export type TGetChosenUsersAndGroupsText = (
  groupsOptions: CDSOption[],
  usersOptions: CDSOption[]
) => string;
export const getChosenUsersAndGroupsText: TGetChosenUsersAndGroupsText = (
  groupsOptions,
  usersOptions
) => {
  const groupsChosenAmount: number = countBy(groupsOptions, 'selected').true;
  const usersChosenAmount: number = countBy(usersOptions, 'selected').true;
  const commaSeparator = usersChosenAmount && groupsChosenAmount ? ', ' : '';

  return `${
    usersChosenAmount
      ? i18n.t('userRoles:assignRolesDrawer:chosenUsersAmount.txt', {
          count: usersChosenAmount,
          amount: usersChosenAmount,
        })
      : ''
  }${commaSeparator}${
    groupsChosenAmount
      ? i18n.t('userRoles:assignRolesDrawer:chosenGroupsAmount.txt', {
          count: groupsChosenAmount,
          amount: groupsChosenAmount,
        })
      : ''
  } ${
    usersChosenAmount || groupsChosenAmount
      ? i18n.t('userRoles:assignRolesDrawer:chosen')
      : ''
  }`;
};

export const getChosenProductInstancesText: (
  translationKey: string,
  amount: number
) => string = (translationKey, amount) => {
  return `${
    amount
      ? i18n.t(translationKey, {
          count: amount,
          amount,
        })
      : ''
  }`;
};

export type TGetSelectedDrawerData = (
  allGroups: IGroupResp[],
  allUsers: IUser[],
  groupsOptions: CDSOption[],
  usersOptions: CDSOption[]
) => IAssignUserRolesRelationsData;
export const getSelectedDrawerData: TGetSelectedDrawerData = (
  allGroups,
  allUsers,
  groupsOptions,
  usersOptions
) => {
  const selectedGroups: IGroupResp[] = reduce(
    groupsOptions,
    (acc: IGroupResp[], groupOption: CDSOption) => {
      if (groupOption.selected) {
        const group = find(allGroups, { id: groupOption.value });
        if (group) {
          acc.push(group);
        }
      }

      return acc;
    },
    []
  );
  const selectedUsers: IUser[] = reduce(
    usersOptions,
    (acc: IUser[], userOption: CDSOption) => {
      if (userOption.selected) {
        const user = find(allUsers, { id: userOption.value });
        if (user) {
          acc.push(user);
        }
      }

      return acc;
    },
    []
  );

  return { selectedGroups, selectedUsers };
};

export const getIsErrorVisible = (field: IField | ISelectField): boolean => {
  const { error, focused, touched } = field;
  return !!error && touched && !focused;
};

export const getIsAbilityErrorVisible = (
  productPermission: IProductCapabilityOption
): boolean => {
  const { items, focused, touched } = productPermission;
  return !focused && touched && !items.some(({ selected }) => selected);
};

export const getApplicationScopeDuplicateError = (
  productCapabilities: IProductCapabilities[],
  productCapabilitiesIndex: number,
  isEditMode?: boolean
): string | undefined => {
  const seenSelections = new Set<string>();

  const indices = [...Array(productCapabilities.length).keys()];
  if (isEditMode) {
    indices.reverse();
  }

  const hasDuplicate = indices.some((capabilityIndex) => {
    const capability = productCapabilities[capabilityIndex];
    const { productPermission, productScope } = capability;

    const selectedPermissions = productPermission.items
      .filter((item) => item.selected)
      .map((item) => item.value);

    let selectedScopes = (productScope?.items?.options ?? [])
      .filter((option) => option.selected)
      .map((option) => option.value);
    selectedScopes = !selectedScopes.length
      ? [productScope.items.selectedOption?.value]
      : selectedScopes;

    const combinations = selectedPermissions.flatMap((permission) =>
      selectedScopes.map((scope) => `${permission}-${scope}`)
    );

    return combinations.some((key) => {
      if (seenSelections.has(key)) {
        if (capabilityIndex === productCapabilitiesIndex) {
          return true;
        }
      }

      seenSelections.add(key);
      return false;
    });
  });

  if (hasDuplicate) {
    return i18n.t(
      'userRoles:customRoleStepper:capabilityDetailsStep:duplicationAppScopeError'
    );
  }

  return undefined;
};

export const getIsProductScopeErrorVisible = (
  productScope: IProductCapabilityOptionGroup
): boolean => {
  const { items, focused, touched } = productScope;
  return (
    !focused &&
    touched &&
    !(items?.options?.some(({ selected }) => selected) || items.selectedOption)
  );
};

export const isCapabilityFormInvalid = (
  capabilities: IProductCapabilities[]
): boolean =>
  capabilities.some(
    ({ productPermission, productScope }, capabilityIndex) =>
      !(
        productPermission.items.some(({ selected }) => selected) &&
        ((productScope?.items?.options ?? []).some(
          ({ selected }) => selected
        ) ||
          productScope?.items?.selectedOption)
      ) || !!getApplicationScopeDuplicateError(capabilities, capabilityIndex)
  );

export const mergeArraysByObjectValue = (
  array1: CDSOption[],
  array2: CDSOption[]
): CDSOption[] => {
  return map(array1, (item) => {
    return assign(
      item,
      find(array2, {
        value: item.value,
      })
    );
  });
};

export const getRolesByProduct = (
  allRoles: IUserRoleResp[],
  selectedOption: CDSOption | null
): IUserRoleResp[] =>
  allRoles.filter(
    (role) =>
      role.product.trim().toLowerCase() ===
      selectedOption?.label?.trim().toLowerCase()
  );

export const getFormattedProductOptions = (products: IProduct[]): CDSOption[] =>
  products.map((product) => ({
    label: product.name,
    value: product.id,
    productKey: product.productKey,
  }));

export const getTableScopes = (
  customRole: ICustomRole
): PermissionTableScopes => {
  if (isEmpty(customRole) || !customRole.scopes) {
    return {};
  }

  return customRole.scopes.reduce(
    (tableScopes: PermissionTableScopes, scope: string) => {
      tableScopes[scope] = true;
      return tableScopes;
    },
    {}
  );
};

export const getCapabilitiesTableData = (
  customRole: ICustomRole
): IProductCapabilitiesData => {
  const productCapabilities = {
    items: [] as IProductCapabilities[],
    error: '',
    loading: false,
  };

  if (isEmpty(customRole) || !customRole.applicationScopeIdToScope) {
    return productCapabilities;
  }

  for (const [value, { applicationScope, scopes }] of Object.entries(
    customRole.applicationScopeIdToScope
  )) {
    for (const scope of scopes) {
      productCapabilities.items.push({
        productScope: {
          items: {
            options: [
              {
                value,
                label: applicationScope,
                selected: true,
              },
            ],
            label: '',
          },
          error: null,
          isLoading: false,
          touched: true,
          focused: false,
        },
        productPermission: {
          items: [
            {
              value: scope,
              label: capitalizeWord(scope),
              selected: true,
            },
          ],
          error: null,
          isLoading: false,
          touched: true,
          focused: false,
        },
        edit: true,
      });
    }
  }

  return productCapabilities;
};

export const getScopesArray = (
  scopes: PermissionTableScopes
): string[] | [] => {
  if (isEmpty(scopes)) return [];
  return Object.keys(scopes);
};

export const groupAbilitiesByScope = (
  capabilities: IProductCapabilitiesTableData[]
): ApplicationScopeIdToScope => {
  return capabilities.reduce<ApplicationScopeIdToScope>((acc, item) => {
    const { abilities, scopeId, scope } = item;
    if (!acc[scopeId]) {
      acc[scopeId] = {
        applicationScope: scope,
        scopes: [],
      };
    }
    // Add the ability to the scopes array
    acc[scopeId].scopes.push(...abilities.map(({ abilityId }) => abilityId));
    return acc;
  }, {});
};

export const getProductAbilityScopes = (
  capabilities: IProductCapabilitiesTableData[]
): string[] => {
  const uniqueAbilities = new Set<string>();

  for (const { abilities } of capabilities) {
    for (const { abilityId } of abilities) {
      uniqueAbilities.add(abilityId);
    }
  }

  return [...uniqueAbilities];
};

export const findStepIndexOneBased = (
  leftPanelSteps: IIndicatorPanelConfig[],
  currentStepperStep: string | null
): number => {
  if (isEmpty(leftPanelSteps) || !currentStepperStep) {
    return 1;
  }
  const index = findIndex(leftPanelSteps, { key: currentStepperStep });
  return index >= 0 ? index + 1 : 1;
};

export const isEditedRoleDifferent = (
  editRole: ICustomRole,
  updateBody: IPartialCustomRole
): boolean => {
  if (editRole.roleDisplayName !== updateBody.roleDisplayName) {
    return true;
  }
  if (editRole.roleDescription !== updateBody.roleDescription) {
    return true;
  }
  if (!isEqual(sortBy(editRole.scopes), sortBy(updateBody.scopes))) {
    return true;
  }
  return false;
};

export const getSelectedRolesByIds: (
  allUserRolesItems: IUserRoleResp[],
  selectedRolesRows: CDSTableSelection
) => IUserRoleResp[] = (allUserRolesItems, selectedRolesRows) => {
  const selectedRolesIds: string[] = Object.keys(selectedRolesRows);

  return filter(allUserRolesItems, (role: IUserRoleResp) =>
    selectedRolesIds.includes(role.id)
  );
};

// Returns Object where key is "productType" of ProductInstance and value - object with properties:
// "product" - Object which contains "productType" and "productName" properties of certain Product,
// "instances" - Array of Product Instances related to this Product.
export const getFilteredInstancesData: (
  allProductsInstancesItems: IProductInstance[],
  selectedRoles: IUserRoleResp[]
) => Record<string, ISelectableProductInstances> = (
  allProductsInstancesItems,
  selectedRoles
) => {
  const selectedRolesProducts = selectedRoles.map(
    (selectedRole: IUserRoleResp) => selectedRole.productKey
  );
  const provisionedProductInstances = getProvisionedProductInstances(
    allProductsInstancesItems
  );

  return reduce(
    provisionedProductInstances,
    (
      acc: Record<string, ISelectableProductInstances>,
      productInstance: IProductInstance
    ) => {
      const { productType, productName } = productInstance;

      if (!selectedRolesProducts.includes(productType)) {
        return acc;
      }

      if (!acc[productType]) {
        acc[productType] = {
          product: {
            productType,
            productName,
          },
          instances: [],
        };
      }

      acc[productType].instances.push(productInstance);

      return acc;
    },
    {}
  );
};

// Filters each Instance of Products and returns only products with single instance if
// at least one User Role of Product was selected
export const getSingleProdictInstances: (
  allProductsInstancesItems: IProductInstance[],
  selectedInstancesIds: Record<string, string[]>,
  selectedRoles: IUserRoleResp[]
) => Record<string, string[]> = (
  allProductsInstancesItems,
  selectedInstancesIds,
  selectedRoles
) => {
  return chain(allProductsInstancesItems)
    .groupBy('productType')
    .filter(
      (instances: IProductInstance[], productKey: string) =>
        instances.length === 1 && !!find(selectedRoles, { productKey })
    )
    .reduce((acc: Record<string, string[]>, instances: IProductInstance[]) => {
      const instance: IProductInstance = instances[0];
      const { productType, id } = instance;
      const existingSelectedProductTypeInstances: string[] =
        acc[productType] || [];
      if (existingSelectedProductTypeInstances.includes(id)) {
        return acc;
      }

      acc[productType] = [...existingSelectedProductTypeInstances, instance.id];
      return acc;
    }, selectedInstancesIds)
    .value();
};

// Returns string of roles separated by comma in format "<role.product> - <role.roleDisplayName>"
export const getRolesDataText: (roles: IUserRoleResp[]) => string = (roles) => {
  const rolesLength = roles.length;

  return reduce(
    roles,
    (acc: string, role: IUserRoleResp, index: number) => {
      const roleTxt = `${getProductDisplayName(role.productKey, role.product)} - ${role.roleDisplayName}`;
      if (!acc.length) {
        return roleTxt;
      }

      return [acc, `${role.product} - ${role.roleDisplayName}`].join(
        getTextJoiner(index, rolesLength)
      );
    },
    ''
  );
};

export const getProductDisplayName = (
  productKey: string | undefined,
  productName: string
): string => {
  return productKey !== ENTERPRISE_PRODUCT_TYPE
    ? productName
    : ENTERPRISE_PRODUCT_NAME;
};

// This function returns joinder for items - helps to get text for 2 items - "A and B" for more than 2 items - "A, B, C, and D".
// Check "getRolesDataText()" as usage example.
export const getTextJoiner: (
  currentItemIndex: number,
  itemsLength: number
) => string = (currentItemIndex, itemsLength) => {
  if (itemsLength === 2) {
    return ` ${i18n.t('userRoles:and')} `;
  }

  const lastItemIndex = itemsLength - 1;
  if (itemsLength > 2 && currentItemIndex === lastItemIndex) {
    return `, ${i18n.t('userRoles:and')} `;
  }

  return ', ';
};

// This function returns joined string from array of strings by rule explained in "getTextJoiner()"
export const getTextJoinedByComma: (items: string[]) => string = (items) => {
  const itemsLength: number = items.length;

  return reduce(
    items,
    (acc, item: string, index: number) => {
      if (!acc.length) {
        return item;
      }

      return [acc, item].join(getTextJoiner(index, itemsLength));
    },
    ''
  );
};

export const getTxtPlurals: (amount: number) => string = (amount) => {
  if (amount === 1) {
    return 'txt_one';
  }
  if (amount === 2) {
    return 'txt_two';
  }

  return 'txt_other';
};

export const getProductNames = (roles: IRbacUserRole[]): string[] =>
  uniq(roles.map((item) => item.product));

export const getProductsManadgedByKpa = (
  products: CDSOption[],
  instances: IProductInstance[]
): CDSOption[] =>
  products
    .filter((option: any) => {
      return instances
        .map((instance) => instance.productType)
        .includes(option.productKey);
    })
    .map((option: any) => {
      const { productKey, ...rest } = option;
      return {
        ...rest,
        productType: productKey,
      };
    });

export const getErrorResponse = (
  error: AxiosError,
  isUserKPA: boolean
): { result: string | null; error: any } => {
  const responseErrorBody =
    error?.response?.data ?? ({ message: '', code: '' } as any);
  const errorTextAboutAlreadyAssignedItem =
    responseErrorBody.message === API_ERROR_USER_ASSIGNED_TO_ROLE ||
    responseErrorBody.message === API_ERROR_GROUP_ASSIGNED_TO_ROLE;
  const isErrorExist = !(
    isUserKPA &&
    responseErrorBody.code === 500 &&
    errorTextAboutAlreadyAssignedItem
  );
  return {
    result: isErrorExist ? ERROR : null,
    error: {
      ...responseErrorBody,
    },
  };
};

export const mapProductCapabilitiesToTableData = (
  productCapabilities: IProductCapabilitiesData,
  isEditMode?: boolean,
  capabilitiesTableSelectedScopes?: IProductCapabilitiesData
): IProductCapabilitiesTableData[] => {
  const scopeMap: Record<string, IProductCapabilitiesTableData> = {};

  productCapabilities.items.forEach(({ productPermission, productScope }) => {
    const abilities =
      productPermission?.items
        .filter(({ selected }) => selected)
        .map(({ label, value }) => ({
          ability: label as string,
          abilityId: value as string,
        })) || [];

    const scope =
      ((productScope?.items?.options ?? []).find(
        ({ selected }) => selected
      ) as CDSOption) || productScope.items.selectedOption;

    if (scope) {
      const scopeKey = scope.value as string;
      if (!scopeMap[scopeKey]) {
        scopeMap[scopeKey] = {
          scope: scope.label as string,
          scopeId: scope.value as string,
          abilities: [],
        };
      }

      scopeMap[scopeKey].abilities.push(...abilities);
    }
  });

  if (isEditMode && capabilitiesTableSelectedScopes) {
    const selectedScopeMap = mapProductCapabilitiesToTableData(
      capabilitiesTableSelectedScopes
    );
    handleEditModeChanges(scopeMap, selectedScopeMap);
  }

  return Object.values(scopeMap);
};

const handleEditModeChanges = (
  scopeMap: Record<string, IProductCapabilitiesTableData>,
  selectedScopeTableData: IProductCapabilitiesTableData[]
): void => {
  Object.values(scopeMap).forEach((productScope) => {
    const selectedScope = selectedScopeTableData.find(
      ({ scopeId }) => productScope.scopeId === scopeId
    );

    if (selectedScope) {
      compareAndUpdateProductScope(productScope, selectedScope);
    } else {
      productScope.status = MappingStatuses.Added;
      productScope.abilities = productScope.abilities.map((ability) => {
        return { ...ability, status: ProductPermissionStatus.ADDED };
      });
    }
  });

  selectedScopeTableData.forEach((selectedScope) => {
    const productScope = scopeMap[selectedScope.scopeId];

    if (!productScope) {
      const deletedScope = {
        ...selectedScope,
        status: MappingStatuses.Deleted,
        abilities: selectedScope.abilities.map((ability) => {
          return { ...ability, status: ProductPermissionStatus.DELETED }; // Mark all abilities as added
        }),
      };
      scopeMap[selectedScope.scopeId] = deletedScope;
    }
  });
};

export const compareAndUpdateProductScope = (
  productScope: IProductCapabilitiesTableData,
  selectedScope: IProductCapabilitiesTableData
): void => {
  const abilitiesAdded: string[] = [];
  const abilitiesDeleted: string[] = [];
  const abilitiesUnchanged: string[] = [];

  const existingAbilityIds = new Set(
    productScope.abilities.map((ability) => ability.abilityId)
  );

  productScope.abilities.forEach((existingAbility) => {
    const isSelected = selectedScope.abilities.some(
      (selectedAbility) =>
        selectedAbility.abilityId === existingAbility.abilityId
    );

    if (isSelected) {
      existingAbility.status = ProductPermissionStatus.UNCHANGED;
      abilitiesUnchanged.push(existingAbility.abilityId);
    } else {
      existingAbility.status = ProductPermissionStatus.ADDED;
      abilitiesAdded.push(existingAbility.abilityId);
    }
  });

  selectedScope.abilities.forEach((selectedAbility) => {
    if (!existingAbilityIds.has(selectedAbility.abilityId)) {
      productScope.abilities.push({
        ...selectedAbility,
        status: ProductPermissionStatus.DELETED,
      });
      abilitiesDeleted.push(selectedAbility.abilityId);
    }
  });

  let status: MappingStatuses;
  switch (true) {
    case !!abilitiesAdded.length && !!abilitiesDeleted.length:
    case !!abilitiesUnchanged.length &&
      (!!abilitiesAdded.length || !!abilitiesDeleted.length):
      status = MappingStatuses.Changed;
      break;
    case !!abilitiesDeleted.length:
      status = MappingStatuses.Deleted;
      break;
    case !!abilitiesAdded.length:
      status = MappingStatuses.Added;
      break;
    default:
      status = MappingStatuses.Unchanged;
  }

  productScope.status = status;
};

export const getSelectedItemLabel = (
  options: CDSOption[]
): string | undefined => options.find(({ selected }) => selected)?.label;

// This can be removed after the feature flag is released.
export const getFilteredProductOptionsByCSW = (
  productOptionsByPermissions: CDSOption[],
  featureFlagsSet: Set<string>
): CDSOption[] => {
  const isCSWEnabled = featureFlagsSet.has(
    FEATURE_FLAG_CONSTANTS.ENHANCE_CUSTOM_ROLE_FOR_CSW
  );

  return isCSWEnabled
    ? productOptionsByPermissions
    : productOptionsByPermissions.filter(
        ({ productKey }: ICDSOption) =>
          productKey !== ProductKeys.SECURE_WORKLOAD
      );
};
