import { range, random } from 'lodash';
import { FilterSubEventType } from 'src/app/enums/ActivityLog';
import { IActivityLog } from 'src/app/interfaces/IActivityLog';

// This mocks should be moved after integration with BE
// to "/spec/unit/app/components/activity-log/mocks.ts"

export const deviceOSs = [
  'Windows 11',
  'Windows 10',
  'Windows 7',
  'Linux Ubuntu',
  'Linux Gentoo',
  'FreeBSD',
  'OpenBSD',
  'Solaris',
  'MacOS',
];
const randomWords = [
  'Lorem',
  'ipsum',
  'dolor',
  'sit',
  'amet,',
  'consectetur',
  'adipiscing',
  'elit,',
  'sed',
  'do',
  'eiusmod',
  'tempor',
  'incididunt',
  'ut',
  'labore',
  'et',
  'dolore',
  'magna',
  'aliqua',
  'Ut',
  'enim',
  'ad',
  'minim',
  'veniam,',
  'quis',
  'nostrud',
  'exercitation',
  'ullamco',
  'laboris',
  'nisi',
  'ut',
  'aliquip',
  'ex',
  'ea',
  'commodo',
  'consequat',
  'duis',
  'aute',
  'irure',
  'dolor',
  'in',
  'reprehenderit',
  'in',
  'voluptate',
  'velit',
  'esse',
  'cillum',
  'dolore',
  'eu',
  'fugiat',
  'nulla',
  'pariatur',
  'excepteur',
  'sint',
  'occaecat',
  'cupidatat',
  'non',
  'proident,',
  'sunt',
  'in',
  'culpa',
  'qui',
  'officia',
  'deserunt',
  'mollit',
  'anim',
  'id',
  'est',
  'laborum',
];
const reversedRandomWords = randomWords.reverse();
const eventTypes = [
  FilterSubEventType.ADMIN_USER_LOGIN_SUCCESS,
  FilterSubEventType.ADMIN_USER_LOGIN_FAILURE,
  FilterSubEventType.IDP_USER_CREATED,
];
const users = [
  'arcu.morbi@google.com',
  'dolor.sit@amet.consectetur',
  'ipsum.ac@icloud.org',
  'occaecat.quis@hotmail.com',
  'iaculis.quis@hotmail.com',
  'magna.cras@outlook.com',
  'mestagna.laborum@outlook.com',
  'sunt.cupidatat@outlook.com',
];

const generateRandomSentance: (
  currentIndex: number,
  wordsInSentance: number,
  words: string[]
) => string = (currentIndex, wordsInSentance, words) => {
  return words.slice(currentIndex / words.length, wordsInSentance).join(' ');
};

const createWordSelector = (words: string[]): Function => {
  let remainingWords = [...words];

  return (): string => {
    if (remainingWords.length === 0) {
      remainingWords = [...words];
    }
    const randomIndex = random(remainingWords.length - 1);
    const [word] = remainingWords.splice(randomIndex, 1);
    return word;
  };
};

const generateDate = (startDate: string, count: number): string[] => {
  const startTime = new Date(startDate).getTime();
  return Array.from({ length: count }, (unused, i) =>
    new Date(startTime - i * 60 * 1000).toISOString()
  );
};

const createDateSelector = (): Function => {
  const dates = generateDate('2024-11-29T07:23:00Z', 100);
  let remainingDates = [...dates];

  return () => {
    if (remainingDates.length === 0) {
      remainingDates = [...dates];
    }
    const word = remainingDates.shift() ?? '';
    return word;
  };
};

// This mocks should be moved  after integration with BE to /spec/unit/app/components/activity-log/mocks.ts
export const GENERATED_MOCK_ACTIVITY_LOG: (size: number) => IActivityLog[] = (
  size
) => {
  const emptyArray = range(0, size);

  return emptyArray.map((index: number) => {
    return {
      description: generateRandomSentance(index, 4, randomWords),
      deviceOs: createWordSelector(deviceOSs)(),
      eventDetails: generateRandomSentance(index, 7, reversedRandomWords),
      eventType: createWordSelector(eventTypes)(),
      id: `uuid_${index}`,
      eventDate: createDateSelector()(),
      user: createWordSelector(users)(),
    };
  });
};

export const eventTypeMapping: Record<string, string> = {
  USERLOGIN_SUCCESS: 'Admin user login',
};

export const eventSubTypeMapping: Record<string, string> = {
  USERLOGIN_SUCCESS: 'User login success',
};
