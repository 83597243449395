import { createSelector } from 'reselect';

import { IState } from 'src/app/core/redux/interfaces';
import { IIdp } from 'src/app/interfaces/IIdentityProvider';
import { FEATURE_FLAG_CONSTANTS } from 'src/app/utils/constant';
import { hasFeatureFlags } from '../common/selectors';

export const getState = (state: IState): IIdp => state.idp || {};

export const getIdp = createSelector([getState], ({ idp }) => idp?.data ?? []);

export const getExternalIdp = createSelector(
  [getState],
  ({ externalIdp }) => externalIdp?.data ?? []
);

export const getIdpData = createSelector([getIdp], (idps) =>
  idps.map((item) => ({
    id: item.id,
    name: item.inputs.idpName,
    link: item.outputs.acsUrl,
    status: item.outputs.isEnabled,
    isExternal: false,
  }))
);

export const getIsAddExternalIdpModalVisible = createSelector(
  [getState],
  ({ addExternalIdp }) =>
    addExternalIdp?.addExternalIdpModal?.isModalVisible ?? false
);

export const getAddExternalIdpModalStep = createSelector(
  [getState],
  ({ addExternalIdp }) => addExternalIdp?.addExternalIdpModal?.step ?? 'add'
);

export const getAddExternalIdpModalIdpIdInputValue = createSelector(
  [getState],
  ({ addExternalIdp }) =>
    addExternalIdp?.addExternalIdpModal?.idpIdInputValue ?? ''
);

export const getVerifyExternalIdpLoadingStatus = createSelector(
  [getState],
  ({ addExternalIdp }) =>
    addExternalIdp?.externalIdpToBeAdded?.isLoading ?? false
);

export const getAddExternalIdpData = createSelector(
  [getState],
  ({ addExternalIdp }) => addExternalIdp?.externalIdpToBeAdded?.data ?? null
);

export const getAddExternalIdpError = createSelector(
  [getState],
  ({ addExternalIdp }) => addExternalIdp?.externalIdpToBeAdded?.error ?? null
);

export const getIdpNotification = createSelector(
  [getState],
  ({ notification }) => ({
    notificationType: notification?.type,
    traceId: notification?.traceId ?? null,
  })
);

export const getIsDeleteExternalIdpModalVisible = createSelector(
  [getState],
  ({ deleteExternalIdp }) =>
    deleteExternalIdp?.deleteExternalIdpModal?.isModalVisible ?? false
);

export const getDeleteExternalIdpData = createSelector(
  [getState],
  ({ deleteExternalIdp }) =>
    deleteExternalIdp?.externalIdpToBeDeleted?.data ?? null
);

export const getDeleteExternalIdpLoadingStatus = createSelector(
  [getState],
  ({ deleteExternalIdp }) => deleteExternalIdp?.isLoading ?? false
);

export const getExternalIdpData = createSelector([getExternalIdp], (idps) =>
  idps.map((item) => ({
    id: item.idpId,
    name: item.idpName,
    link: item.idpDomain,
    status: true,
    isExternal: true,
    groupMappings: item.groupMappings,
  }))
);

export const getAllIdps = createSelector(
  [
    getIdpData,
    getExternalIdpData,
    (state) => hasFeatureFlags(state, [FEATURE_FLAG_CONSTANTS.EXTERNAL_IDP]),
  ],
  (idp, externalIdp, hasExternalIdpFeatureFlag) => {
    return [...idp, ...(hasExternalIdpFeatureFlag ? externalIdp : [])];
  }
);
