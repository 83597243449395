import { put, select, takeLatest, delay, call } from 'redux-saga/effects';
import { prometheusAPIClient } from 'src/app/api/clients';
import { IActionWithPayload } from 'src/app/core/redux/interfaces';
import { IBootstrap } from 'src/app/interfaces/ICommon';
import {
  IGetIdp,
  IUpdateGroupMappingsPayload,
  IVerifyIdpResponse,
} from 'src/app/interfaces/IIdentityProvider';
import { notifyFormPopulated } from 'src/app/services/formPopulated';
import {
  FetchIdentityProviders,
  FetchExternalIdentityProviders,
  VerifyExternalIdp,
  CreateExternalIdp,
  ChangeAddExternalIdpModalStep,
  AddIdpNotification,
  HideAddExternalIdpModal,
  ClearAddExternalIdpData,
  UpdateGroupMappings,
  DeleteExternalIdp,
  HideDeleteExternalIdpModal,
  ClearDeleteExternalIdpData,
} from './actions';
import * as Paths from '../../../app/paths';
import { IdpNotificationType } from '../../enums/Idps';
import { getBootstrapConfig, getNavigation } from '../common/selectors';
import { getEnterpriseId } from '../enterprises/selectors';
import { FetchGroupMappings } from '../groups/actions';

export function* getIdp(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  try {
    const response: IGetIdp = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/idps`
    );
    const idps = response.data;
    yield put(FetchIdentityProviders.SUCCESS(idps));
  } catch (error) {
    yield put(FetchIdentityProviders.ERROR(error as Error));
  }
}
export function* getExternalIdps(): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  try {
    const response: IGetIdp = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/authorizedExternalIdps`
    );
    const idps = response.data;
    yield put(FetchExternalIdentityProviders.SUCCESS(idps));
  } catch (error) {
    yield put(FetchExternalIdentityProviders.ERROR(error as Error));
  }
}

export function* verifyExternalIdp(
  action: IActionWithPayload<string>
): Generator {
  try {
    const externalIdpId = action.payload;
    const response: IVerifyIdpResponse = yield prometheusAPIClient.get(
      `/api/verifyIdP/${externalIdpId}`
    );
    const externalIdp = response.data;
    yield put(VerifyExternalIdp.SUCCESS(externalIdp));
    yield put(ChangeAddExternalIdpModalStep('confirm'));
  } catch (error) {
    yield put(VerifyExternalIdp.ERROR(error as Error));
    yield put(
      AddIdpNotification({
        type: IdpNotificationType.CREATE_EXTERNAL_IDP_ERROR,
        traceId: (error as any)?.response?.data?.traceId ?? null,
      })
    );
    yield put(HideAddExternalIdpModal());
    yield put(ClearAddExternalIdpData());
    yield delay(1000);
    yield call(notifyFormPopulated, { isDirty: true });
  }
}

export function* createExternalIdp(
  action: IActionWithPayload<string>
): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  try {
    const externalIdpId = action.payload;
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/authorizedExternalIdps`,
      {
        idpId: externalIdpId,
      }
    );
    yield put(CreateExternalIdp.SUCCESS());
    yield put(HideAddExternalIdpModal());
    yield put(ClearAddExternalIdpData());
    yield put(
      AddIdpNotification({
        type: IdpNotificationType.CREATE_EXTERNAL_IDP_SUCCESS,
      })
    );
    yield put(FetchExternalIdentityProviders.PENDING());
  } catch (error) {
    yield put(CreateExternalIdp.ERROR(error as Error));
    yield put(
      AddIdpNotification({
        type: IdpNotificationType.CREATE_EXTERNAL_IDP_ERROR,
        traceId: (error as any)?.response?.data?.traceId ?? null,
      })
    );
    yield put(HideAddExternalIdpModal());
    yield put(ClearAddExternalIdpData());
    yield delay(1000);
    yield call(notifyFormPopulated, { isDirty: true });
  }
}

export function* updateGroupMappings(
  action: IActionWithPayload<IUpdateGroupMappingsPayload>
): Generator {
  const { basePath, isStandalone } = (yield select(
    getBootstrapConfig
  )) as IBootstrap;
  const { groupMappings, onRequestEnd } = action.payload;
  const { navigateFn } = (yield select(getNavigation)) as any;
  const basepath = isStandalone ? '' : basePath;
  const path = `${basepath}/${Paths.GROUPS}`;

  try {
    const enterpriseId = (yield select(getEnterpriseId)) as string;

    yield prometheusAPIClient.put(
      `/api/enterprises/${enterpriseId}/groupMappings`,
      {
        groupMappings,
      }
    );
    yield put(UpdateGroupMappings.SUCCESS());

    yield put(
      AddIdpNotification({
        type: IdpNotificationType.UPDATE_GROUP_MAPPINGS_SUCCESS,
      })
    );
    yield put(FetchExternalIdentityProviders.PENDING());
    yield put(FetchGroupMappings.PENDING());
  } catch (error) {
    yield put(UpdateGroupMappings.ERROR(error as Error));
    yield put(
      AddIdpNotification({
        type: IdpNotificationType.UPDATE_GROUP_MAPPINGS_ERROR,
        traceId: (error as any)?.response?.data?.traceId ?? null,
      })
    );
    yield delay(1000);
    yield call(notifyFormPopulated, { isDirty: true });
  } finally {
    onRequestEnd();

    yield call(navigateFn, path);
    // NOTE: "notifyFormPopulated" action is called to notify shell app
    // that banner should be closed after user leaves groups page.
    // Only with "isDirty: true" param the "routeChanged" event is triggered.
    yield call(notifyFormPopulated, { isDirty: true });
  }
}
export function* deleteExternalIdp(
  action: IActionWithPayload<string>
): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;

  try {
    const externalIdpId = action.payload;
    yield prometheusAPIClient.delete(
      `/api/enterprises/${enterpriseId}/authorizedExternalIdps/${externalIdpId}`
    );
    yield put(DeleteExternalIdp.SUCCESS());
    yield put(HideDeleteExternalIdpModal());
    yield put(ClearDeleteExternalIdpData());
    yield put(
      AddIdpNotification({
        type: IdpNotificationType.DELETE_EXTERNAL_IDP_SUCCESS,
      })
    );
    yield put(FetchExternalIdentityProviders.PENDING());
  } catch (error) {
    yield put(DeleteExternalIdp.ERROR(error as Error));
    yield put(
      AddIdpNotification({
        type: IdpNotificationType.DELETE_EXTERNAL_IDP_ERROR,
        traceId: (error as any)?.response?.data?.traceId ?? null,
      })
    );
    yield put(HideDeleteExternalIdpModal());
    yield put(ClearDeleteExternalIdpData());
    yield delay(1000);
    yield call(notifyFormPopulated, { isDirty: true });
  }
}

export function* idpSaga(): Generator {
  yield takeLatest(FetchIdentityProviders.Pending.TYPE, getIdp);
  yield takeLatest(VerifyExternalIdp.Pending.TYPE, verifyExternalIdp);
  yield takeLatest(CreateExternalIdp.Pending.TYPE, createExternalIdp);
  yield takeLatest(UpdateGroupMappings.Pending.TYPE, updateGroupMappings);
  yield takeLatest(DeleteExternalIdp.Pending.TYPE, deleteExternalIdp);
  yield takeLatest(
    FetchExternalIdentityProviders.Pending.TYPE,
    getExternalIdps
  );
}
