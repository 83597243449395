import { AxiosResponse } from 'axios';
import { map, reduce, some } from 'lodash';
import _toUpper from 'lodash/toUpper';
import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { ProductKeys } from 'src/app/enums/Products';
import { notifyFormPopulated } from 'src/app/services/formPopulated';
import {
  FEATURE_FLAG_CONSTANTS,
  FEATURE_FLAG_PREFIX,
} from 'src/app/utils/constant';
import {
  AddUserRoleNotification,
  AssignUserRolesRelations,
  CreateCustomRole,
  DeleteUserRole,
  FetchAllGroups,
  FetchCustomRole,
  FetchProductPermissions,
  FetchApplicationScopes,
  FetchProductsCustomRole,
  FetchRoleToGroupsRelations,
  FetchRoleToUsersRelations,
  FetchUserRoles,
  HandleOnDeleteUserRoleButtonClick,
  SetCustomRoleDirty,
  SetDeleteCustomUserRole,
  ToggleDeleteUserRoleModal,
  UnassignGroupFromRole,
  UnassignUserFromRole,
  UpdateCustomRole,
  SetApplicationScopeSearchTerm,
} from './actions';
import {
  groupAbilitiesByScope,
  getErrorResponse,
  getFormattedProductOptions,
  getRolesDataText,
  getScopesArray,
  getTableScopes,
  isEditedRoleDifferent,
  getProductAbilityScopes,
  getCapabilitiesTableData,
} from './helpers';
import {
  getActiveDeleteUserRole,
  getActiveEditCustomRoleId,
  getAssignUserRolesDrawerData,
  getEditingUserRole,
  getFetchEditCustomRole,
  getPermissionTableSelectedScopes,
  getRoleDetailsStepFields,
  getSelectedProductId,
  getSelectedUserRoles,
  getSelectedProductKey,
  getProductCapabilitiesTableData,
} from './selectors';
import { prometheusAPIClient } from '../../api/clients';
import { IActionWithPayload } from '../../core/redux/interfaces';
import { UserRoleNotificationType, UserRoleType } from '../../enums/UserRoles';
import { IUser } from '../../interfaces/ICommon';
import {
  IApiPostCall,
  IAssignUserRolesDrawerData,
  IAssignUserRolesRelationsData,
  IAssignUserRolesRelationsRequestData,
  IAssignUserRolesRelationsSuccess,
  ICustomRole,
  IGroup,
  IGroupResp,
  IHandleUnassignUserFromRolePayload,
  IProductResponse,
  IRoleToGroupRelationResp,
  IRoleToGroupsRelationsResp,
  IRoleToUserRelation,
  ISelectedUserRoles,
  IUnassignGroup,
  IUserRoleResp,
  RbacCustomRolesResponseType,
  RbacRolesResponseType,
  RbacBundledRolesResponseType,
  IProductPermissionResponse,
  RbacCustomRolesApplicationScopesResponseType,
  ISearchTermInput,
} from '../../interfaces/IUserRoles';
import * as Paths from '../../paths';
import { getCombinedRoles } from '../../utils/shared';
import {
  getBootstrapConfig,
  getFeatureFlagsSet,
  getNavigation,
} from '../common/selectors';
import { getEnterpriseId } from '../enterprises/selectors';
import { FetchAllRoles } from '../users/actions';
import { getIsKPA } from '../users/selectors';

export function* handleFetchAllGroups(): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  try {
    const response: IGroup[] | any = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/rbac/groups`
    );

    yield put(FetchAllGroups.SUCCESS(response.data.rbacGroups));
  } catch (error) {
    yield put(FetchAllGroups.ERROR(error as Error));
  }
}

export function* handleFetchUserRoles(): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  const featureFlagsSet = (yield select(getFeatureFlagsSet)) as Set<string>;

  try {
    const staticRolesResponse: RbacRolesResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/roles`
      );
    const customRolesResponse: RbacCustomRolesResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/customRoles`
      );
    const bundledRolesResponse: RbacBundledRolesResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/bundledRoles`
      );

    const allRoles = getCombinedRoles(
      staticRolesResponse,
      customRolesResponse,
      featureFlagsSet.has(FEATURE_FLAG_CONSTANTS.BUNDLED_ROLE)
        ? bundledRolesResponse
        : null
    ).filter((role) =>
      new Set(
        role.featureFlags?.map((flag) =>
          _toUpper(flag).replace(FEATURE_FLAG_PREFIX, '')
        )
      ).isSubsetOf(featureFlagsSet)
    );
    yield put(FetchUserRoles.SUCCESS(allRoles));
  } catch (error) {
    yield put(FetchUserRoles.ERROR(error as Error));
  }
}

export function* handleFetchRoleToUsersRelations(
  action: IActionWithPayload<string>
): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  try {
    const response: IRoleToUserRelation[] | any = yield prometheusAPIClient.get(
      `/api/enterprises/${enterpriseId}/rbac/roles/${action.payload}/users`
    );
    yield put(FetchRoleToUsersRelations.SUCCESS(response.data.rbacUsersByRole));
  } catch (error) {
    yield put(FetchRoleToUsersRelations.ERROR(error as Error));
  }
}

export function* handleUnassignUserFromRole(
  action: IActionWithPayload<IHandleUnassignUserFromRolePayload>
): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  const { userToRoleRelation, user } = action.payload;

  try {
    yield prometheusAPIClient.delete(
      `/api/enterprises/${enterpriseId}/rbac/users/${userToRoleRelation.userId}/roles/${userToRoleRelation.id}`
    );
    yield put(FetchRoleToUsersRelations.PENDING(userToRoleRelation.roleId));
    yield put(UnassignUserFromRole.SUCCESS(user));
  } catch (error) {
    yield put(UnassignUserFromRole.ERROR(error as Error));
  }
}

export function* handleUnassignGroupFromRole(
  action: IActionWithPayload<IUnassignGroup>
): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  const { group, userRoleId } = action.payload;

  try {
    yield prometheusAPIClient.delete(
      `/api/enterprises/${enterpriseId}/rbac/groups/${group.id}/roles/${group.roleToGroupRelationId}`
    );

    yield put(FetchRoleToGroupsRelations.PENDING(userRoleId));
    yield put(UnassignGroupFromRole.SUCCESS(group));
  } catch (error) {
    yield put(UnassignGroupFromRole.ERROR(error as Error));
  }
}

export function* handleFetchRoleToGroupsRelations(
  action: IActionWithPayload<string>
): Generator {
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  try {
    const response: { data: { rbacGroups: IRoleToGroupRelationResp[] } } | any =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/roles/${action.payload}/groups`
      );
    const roleToGroupsRelations: IRoleToGroupsRelationsResp[] =
      response.data.rbacGroups;

    yield put(FetchRoleToGroupsRelations.SUCCESS(roleToGroupsRelations));
  } catch (error) {
    yield put(FetchRoleToGroupsRelations.ERROR(error as Error));
  }
}

export function* handleOnDeleteUserRoleButtonClick(
  action: IActionWithPayload<IUserRoleResp>
): Generator {
  const { id } = action.payload;

  yield put(SetDeleteCustomUserRole(action.payload));
  yield put(FetchRoleToGroupsRelations.PENDING(id));
  yield put(FetchRoleToUsersRelations.PENDING(id));
  yield put(ToggleDeleteUserRoleModal(true));
}

// Universal method for post call add Error catch.
// Does not break "yield all" because catches responses with errors.
// If error occures - catches it and returns received error object
export function* handleApiPostCall(
  requestData: IApiPostCall,
  isUserKPA: boolean
): any {
  const { url, body } = requestData;

  return yield prometheusAPIClient
    .post(url, body)
    .catch((error) => getErrorResponse(error, isUserKPA));
}

export function* handleAssignUserRolesRelations(
  action: IActionWithPayload<IAssignUserRolesRelationsData>
): Generator {
  const isUserKPA = (yield select(getIsKPA)) as boolean;
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const selectedUserRoles = (yield select(
    getSelectedUserRoles
  )) as ISelectedUserRoles;
  const { selectedInstancesIds } = (yield select(
    getAssignUserRolesDrawerData
  )) as IAssignUserRolesDrawerData;
  const { selectedGroups, selectedUsers } = action.payload;
  const preparedData: IAssignUserRolesRelationsRequestData = reduce(
    selectedUserRoles.items,
    (
      acc: IAssignUserRolesRelationsRequestData,
      selectedRole: IUserRoleResp
    ) => {
      const productType: string = selectedRole.productKey ?? '';
      const productInstancesIds = selectedInstancesIds[productType];
      const isRoleBundled = selectedRole.type === UserRoleType.BUNDLED;

      if (productInstancesIds) {
        productInstancesIds.forEach((instanceId: string) => {
          const assignGroupsResults: IApiPostCall[] = map(
            selectedGroups,
            (group: IGroupResp) => {
              return {
                url: `/api/enterprises/${enterpriseId}/rbac/groups/${group.id}/roles`,
                body: isRoleBundled
                  ? { roleId: selectedRole.id }
                  : { roleId: selectedRole.id, tenantId: instanceId },
              };
            }
          );
          const assignUsersResults: IApiPostCall[] = map(
            selectedUsers,
            (user: IUser) => {
              return {
                url: `/api/enterprises/${enterpriseId}/rbac/users/${user.id}/roles`,
                body: isRoleBundled
                  ? { roleId: selectedRole.id }
                  : { roleId: selectedRole.id, tenantId: instanceId },
              };
            }
          );

          acc.groups.push(...assignGroupsResults);
          acc.users.push(...assignUsersResults);
        });
      }

      return acc;
    },
    { groups: [], users: [] }
  );

  const result: IAssignUserRolesRelationsSuccess | any = yield all({
    groupsResponses: yield all(
      preparedData.groups.map((data: IApiPostCall) =>
        handleApiPostCall(data, isUserKPA)
      )
    ),
    usersResponses: yield all(
      preparedData.users.map((data: IApiPostCall) =>
        handleApiPostCall(data, isUserKPA)
      )
    ),
  });

  // Fetch updated relations only if one role selected because in this case
  // user is on "Edit Role" page and could see relations
  if (selectedUserRoles.items.length === 1) {
    const { id } = selectedUserRoles.items[0];
    yield put(FetchRoleToGroupsRelations.PENDING(id));
    yield put(FetchRoleToUsersRelations.PENDING(id));
  }

  const hasErrors: boolean =
    some(result.groupsResponses, (response) => response.error) ||
    some(result.usersResponses, (response) => response.error);

  yield put(
    // TODO: we do not use ever AssignUserRolesRelations.ERROR
    AssignUserRolesRelations.SUCCESS({
      ...result,
      hasErrors,
      rolesNamesText: !hasErrors
        ? ''
        : getRolesDataText(selectedUserRoles.items),
      selectedGroupsAmount: selectedGroups.length,
      selectedUsersAmount: selectedUsers.length,
    })
  );
}

export function* handleFetchProductsForCustomRole(): Generator {
  try {
    const response = (yield prometheusAPIClient.get(
      `/api/rbac/customRoles/products`
    )) as AxiosResponse<IProductResponse>;

    const formattedOptions = getFormattedProductOptions(
      response.data.customRoleProducts
    );
    yield put(
      FetchProductsCustomRole.SUCCESS({
        formattedOptions,
        customRoleProducts: response.data.customRoleProducts,
      })
    );
  } catch (error) {
    yield put(FetchProductsCustomRole.ERROR(error as Error));
  }
}

export function* handleFetchProductPermissions(): Generator {
  // This handler is shared between two components: CustomRoleStepper and UserRolePermissionsTable
  // Get productId from the stepper Redux state first, if it doesn't exist, get it from the editingUserRole Redux state
  let productId = yield select(getSelectedProductId);
  if (!productId) {
    const editingUserRole = yield select(getEditingUserRole);
    productId = (editingUserRole as IUserRoleResp)?.productId;
  }
  try {
    const response = (yield prometheusAPIClient.get(
      `/api/rbac/customRoles/products/${productId}/permissions`
    )) as IProductPermissionResponse;

    yield put(FetchProductPermissions.SUCCESS(response.data));
  } catch (error) {
    yield put(FetchProductPermissions.ERROR(error as Error));
  }
}

export function* handleFetchCustomRole(): Generator {
  const enterpriseId = yield select(getEnterpriseId);
  const roleId = yield select(getActiveEditCustomRoleId);

  try {
    const response: AxiosResponse<ICustomRole> | any =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/customRoles/${roleId}`
      );
    yield put(SetCustomRoleDirty(false));
    yield put(
      FetchCustomRole.SUCCESS({
        customRole: response.data,
        tableScopes: getTableScopes(response.data),
        productCapabilities: getCapabilitiesTableData(response.data),
      })
    );
  } catch (error) {
    yield put(FetchCustomRole.ERROR(error as Error));
  }
}

export function* handleCreateCustomRoles(): Generator<any, void, any> {
  const { basePath, isStandalone } = yield select(getBootstrapConfig);
  const { navigateFn } = yield select(getNavigation);
  const enterpriseId = yield select(getEnterpriseId);
  const productId = yield select(getSelectedProductId);
  const roleDetails = yield select(getRoleDetailsStepFields);
  const selectedPermissions = yield select(getPermissionTableSelectedScopes);
  const productKey = yield select(getSelectedProductKey);
  const selectedProductCapabilities = yield select(
    getProductCapabilitiesTableData
  );

  const isSecureWorkloadSelected = productKey === ProductKeys.SECURE_WORKLOAD;
  const basepath = isStandalone ? '' : basePath;
  const path = `${basepath}/${Paths.USER_ROLES}`;

  try {
    yield prometheusAPIClient.post(
      `/api/enterprises/${enterpriseId}/rbac/customRoles`,
      {
        productId,
        roleDisplayName: roleDetails.roleNameField.value.trim(),
        roleDescription: roleDetails.roleDescriptionField.value.trim(),
        scopes: getScopesArray(selectedPermissions),
        ...(isSecureWorkloadSelected
          ? {
              applicationScopeIdToScope: groupAbilitiesByScope(
                selectedProductCapabilities
              ),
              scopes: getProductAbilityScopes(selectedProductCapabilities),
            }
          : {}),
      }
    );
    yield put(SetCustomRoleDirty(false));
    yield put(CreateCustomRole.SUCCESS());
    yield put(
      AddUserRoleNotification({
        type: UserRoleNotificationType.CREATE_USER_ROLE_SUCCESS,
        roleName: roleDetails.roleNameField.value,
      })
    );
    yield call(navigateFn, path);
  } catch (error) {
    yield put(CreateCustomRole.ERROR(error as Error));
    yield put(SetCustomRoleDirty(true));
    yield put(
      AddUserRoleNotification({
        type: UserRoleNotificationType.CREATE_USER_ROLE_ERROR,
        roleName: roleDetails.roleNameField.value,
      })
    );
    yield call(navigateFn, path);
  }
}

export function* handleUpdateCustomRoles(): Generator<any, void, any> {
  const { basePath, isStandalone } = yield select(getBootstrapConfig);
  const { navigateFn } = yield select(getNavigation);
  const enterpriseId = yield select(getEnterpriseId);
  const editRoleId = yield select(getActiveEditCustomRoleId);
  const productId = yield select(getSelectedProductId);
  const roleDetails = yield select(getRoleDetailsStepFields);
  const selectedPermissions = yield select(getPermissionTableSelectedScopes);
  const fetchEditCustomRole = yield select(getFetchEditCustomRole);
  const productKey = yield select(getSelectedProductKey);
  const selectedProductCapabilities = yield select(
    getProductCapabilitiesTableData
  );

  const basepath = isStandalone ? '' : basePath;
  const path = `${basepath}/${Paths.USER_ROLES}`;
  const isSecureWorkloadSelected = productKey === ProductKeys.SECURE_WORKLOAD;

  const updateBody = {
    productId,
    roleDisplayName: roleDetails.roleNameField.value.trim(),
    roleDescription: roleDetails.roleDescriptionField.value.trim(),
    scopes: getScopesArray(selectedPermissions),
    ...(isSecureWorkloadSelected
      ? {
          applicationScopeIdToScope: groupAbilitiesByScope(
            selectedProductCapabilities
          ),
          scopes: getProductAbilityScopes(selectedProductCapabilities),
        }
      : {}),
  };
  const isRoleUpdated = isEditedRoleDifferent(fetchEditCustomRole, updateBody);

  try {
    yield prometheusAPIClient.put(
      `/api/enterprises/${enterpriseId}/rbac/customRoles/${editRoleId}`,
      updateBody
    );
    yield put(SetCustomRoleDirty(false));
    yield put(UpdateCustomRole.SUCCESS());
    yield put(
      AddUserRoleNotification({
        type: UserRoleNotificationType.EDIT_USER_ROLE_SUCCESS,
        roleName: roleDetails.roleNameField.value,
        options: { isRoleUpdated },
      })
    );
    yield call(navigateFn, path);
  } catch (error) {
    yield put(UpdateCustomRole.ERROR(error as Error));
    yield put(SetCustomRoleDirty(true));
    yield put(
      AddUserRoleNotification({
        type: UserRoleNotificationType.EDIT_USER_ROLE_ERROR,
        roleName: roleDetails.roleNameField.value,
      })
    );
    yield call(navigateFn, path);
  }
}

export function* handleDeleteUserRole(
  action: IActionWithPayload<string>
): Generator<any, void, any> {
  const roleId = action.payload;

  const { basePath, isStandalone } = yield select(getBootstrapConfig);
  const { navigateFn } = yield select(getNavigation);
  const enterpriseId = (yield select(getEnterpriseId)) as string;
  const { roleDisplayName } = (yield select(
    getActiveDeleteUserRole
  )) as IUserRoleResp;

  const basepath = isStandalone ? '' : basePath;
  const path = `${basepath}/${Paths.USER_ROLES}`;

  try {
    yield prometheusAPIClient.delete(
      `/api/enterprises/${enterpriseId}/rbac/customRoles/${roleId}`
    );
    yield put(SetCustomRoleDirty(false));
    yield put(
      AddUserRoleNotification({
        type: UserRoleNotificationType.DELETE_USER_ROLE_SUCCESS,
        roleName: roleDisplayName,
      })
    );
    yield put(DeleteUserRole.SUCCESS());
    yield put(FetchUserRoles.PENDING());
    yield put(FetchAllRoles.PENDING());
    yield call(navigateFn, path);
  } catch (error) {
    yield put(SetCustomRoleDirty(true));
    yield put(
      AddUserRoleNotification({
        type: UserRoleNotificationType.DELETE_USER_ROLE_ERROR,
        roleName: roleDisplayName,
      })
    );
    yield put(DeleteUserRole.ERROR(error as Error));
  }
}

export function* handleSetCustomRoleDirty(
  action: IActionWithPayload<boolean>
): Generator {
  const { payload: isDirty } = action;

  yield delay(1000);
  yield call(notifyFormPopulated, { isDirty });
}

export function* handleFetchProductScopes(): Generator<any, void, any> {
  const enterpriseId = yield select(getEnterpriseId);

  try {
    const response: RbacCustomRolesApplicationScopesResponseType =
      yield prometheusAPIClient.get(
        `/api/enterprises/${enterpriseId}/rbac/customRoles/applicationScopes`
      );

    yield put(FetchApplicationScopes.SUCCESS(response.data));
  } catch (error) {
    yield put(FetchApplicationScopes.ERROR(error as Error));
  }
}

export function* handleFetchProductScopesBySearchTerm(
  action?: IActionWithPayload<ISearchTermInput>
): Generator<any, void, any> {
  const enterpriseId = yield select(getEnterpriseId);
  const { searchTerm, applicationScopeIndex } =
    action?.payload as ISearchTermInput;

  const requestUrl = searchTerm
    ? `/api/enterprises/${enterpriseId}/rbac/customRoles/applicationScopes?name=${encodeURIComponent(searchTerm)}`
    : `/api/enterprises/${enterpriseId}/rbac/customRoles/applicationScopes`;

  try {
    const response: RbacCustomRolesApplicationScopesResponseType =
      yield prometheusAPIClient.get(requestUrl);

    yield put(
      SetApplicationScopeSearchTerm.SUCCESS({
        response: response.data,
        applicationScopeIndex,
      })
    );
  } catch (error) {
    yield put(
      SetApplicationScopeSearchTerm.ERROR({ error, applicationScopeIndex })
    );
  }
}

export function* userRolesSaga(): Generator {
  yield takeLatest(FetchAllGroups.Pending.TYPE, handleFetchAllGroups);
  yield takeLatest(FetchUserRoles.Pending.TYPE, handleFetchUserRoles);
  yield takeLatest(
    FetchRoleToUsersRelations.Pending.TYPE,
    handleFetchRoleToUsersRelations
  );
  yield takeEvery(
    HandleOnDeleteUserRoleButtonClick.TYPE,
    handleOnDeleteUserRoleButtonClick
  );
  yield takeLatest(DeleteUserRole.Pending.TYPE, handleDeleteUserRole);
  yield takeLatest(
    UnassignUserFromRole.Pending.TYPE,
    handleUnassignUserFromRole
  );
  yield takeLatest(
    UnassignGroupFromRole.Pending.TYPE,
    handleUnassignGroupFromRole
  );
  yield takeLatest(
    FetchRoleToGroupsRelations.Pending.TYPE,
    handleFetchRoleToGroupsRelations
  );
  yield takeEvery(
    AssignUserRolesRelations.Pending.TYPE,
    handleAssignUserRolesRelations
  );
  yield takeLatest(
    FetchProductsCustomRole.Pending.TYPE,
    handleFetchProductsForCustomRole
  );
  yield takeLatest(
    FetchProductPermissions.Pending.TYPE,
    handleFetchProductPermissions
  );
  yield takeLatest(FetchCustomRole.Pending.TYPE, handleFetchCustomRole);
  yield takeLatest(CreateCustomRole.Pending.TYPE, handleCreateCustomRoles);
  yield takeLatest(UpdateCustomRole.Pending.TYPE, handleUpdateCustomRoles);
  yield takeLatest(SetCustomRoleDirty.TYPE, handleSetCustomRoleDirty);
  yield takeLatest(
    FetchApplicationScopes.Pending.TYPE,
    handleFetchProductScopes
  );
  yield takeLatest(
    SetApplicationScopeSearchTerm.Pending.TYPE,
    handleFetchProductScopesBySearchTerm
  );
}

export function* bundledRolesSaga(): Generator {
  yield takeLatest(FetchAllGroups.Pending.TYPE, handleFetchAllGroups);
  yield takeLatest(FetchUserRoles.Pending.TYPE, handleFetchUserRoles);
  yield takeLatest(
    FetchRoleToUsersRelations.Pending.TYPE,
    handleFetchRoleToUsersRelations
  );
}
